@font-face {
	font-family: "icon-font";
	src: url("icon-font.eot?b7314da1e6bc86299c6d29e831d6fa17?#iefix") format("embedded-opentype"),
url("icon-font.woff2?b7314da1e6bc86299c6d29e831d6fa17") format("woff2"),
url("icon-font.woff?b7314da1e6bc86299c6d29e831d6fa17") format("woff");
}


.icon:before {
	font-family: icon-font !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-apple:before {
	content: "\f101";
}
.icon-chevron-left-circle:before {
	content: "\f102";
}
.icon-chevron-right-circle:before {
	content: "\f103";
}
.icon-facebook:before {
	content: "\f104";
}
.icon-format-quote-open:before {
	content: "\f105";
}
.icon-google-chrome:before {
	content: "\f106";
}
.icon-google-play:before {
	content: "\f107";
}
.icon-numeric-1-box:before {
	content: "\f108";
}
.icon-numeric-2-box:before {
	content: "\f109";
}
.icon-numeric-3-box:before {
	content: "\f10a";
}
.icon-star-half:before {
	content: "\f10b";
}
.icon-star:before {
	content: "\f10c";
}
.icon-twitter:before {
	content: "\f10d";
}
