// Custom variables (including functions and mix-ins)
// E.g., p { @import font-size($h1-font-size); }
@import "~themes/bootstrap-variables";

// Local class selectors go here. 
// Applied to only DOM elements directly defined in this component.

.component {

  :global {
    // Global class selectors go here.
    // Applied to all DOM elements nested within this component.
    
  }
}

.ctaBtn {
  width: 16rem;
}

.ctaBtnSep {
  position: relative;
  top: -0.75rem;
}
