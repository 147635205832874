// Custom variables (including functions and mix-ins)
// E.g., p { @import font-size($h1-font-size); }
@import "~themes/bootstrap-variables";

// Local class selectors go here. 
// Applied to only DOM elements directly defined in this component.

.component {

  :global {
    // Global class selectors go here.
    // Applied to all DOM elements nested within this component.
    
  }
}

.shareBtn {
  border-radius: 9999rem;
  height: 2.375rem;
  width: 2.375rem;
  padding: 0 0 0 0.5rem;
  line-height: 2.25rem;
}
