// Custom variables (including functions and mix-ins)
// E.g., p { @import font-size($h1-font-size); }
@import "~themes/bootstrap-variables";

:export {
  sm: map-get($grid-breakpoints, sm);
  md: map-get($grid-breakpoints, md);
  lg: map-get($grid-breakpoints, lg);
  xl: map-get($grid-breakpoints, xl);
}

// Local class selectors go here.
// Applied to only DOM elements directly defined in this component.

.component {
  :global {
    // Global class selectors go here.
    // Applied to all DOM elements nested within this component.
  }
}

.header {
  background: url(~media/header-v.jpg) center bottom no-repeat scroll;
  background-size: cover;
  background-color: #befcc0;
  position: relative;
  // border-radius: 0 0 50% 50% / 0 0 5rem 5rem;
}

@include media-breakpoint-up(sm) {
  .header {
    background-image: url(~media/header-h.jpg);
  }
}

.header::after {
  content: "";
  display: block;
  // padding-bottom: 3%;
  background: url(~media/header-tail.svg) center bottom no-repeat scroll;
  background-size: cover;
  height: 8vw;
  width: 100%;

  position: relative;
  bottom: -1px;
}

.headerCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(#66ff99 15%, rgba(0, 255, 255, 0) 85%);
  opacity: 0;
}

.headerFg {
  position: relative;
}

.headerText {
  color: #145708;
}

.navbarBrand {
  color: $black-50 !important;
  font-weight: bold;
  letter-spacing: 0.125rem;
}

.navbarLogo {
  width: 2.5rem;
  height: 2.5rem;
  filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.1));
}

.nav {
  :global(.nav-link) {
    color: $black-50 !important;
  }
  font-weight: 500;
}

.jumbotron {
  // color: darken($secondary-dark, 10%);
  background-color: transparent;
}

.sectionTitle {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 3rem;
  // color: darken($primary-dark, 5%);
}

.features {
  background-color: #00ACC1;
  background-image: linear-gradient(205deg, #fff 93%, #b3ffb3 99%);
  overflow-x: hidden;
}


.features::after {
  content: "";
  display: block;
  // padding-bottom: 3%;
  background: url(~media/habit.png) right bottom no-repeat scroll;
  background-size: cover;
  height: 10rem;

  position: relative;
}

@include media-breakpoint-up(md) {
  .features {
    background-image: linear-gradient(195deg, #fff 85%, #b3ffb3 95%);
  }

  .features::after {
    height: 12rem;
  }
}

@include media-breakpoint-up(xl) {
  .features::after {
    height: 18vw;
  }
}


.howText {
  text-indent: -1rem;
  padding-left: 1.5rem;
  margin-top: 1rem;
}

.footnote {
  text-indent: -0.7rem;
  padding-left: 1.5rem;
  margin-top: 1rem;
}


.screenshot {
  height: 20rem;
}

@include media-breakpoint-up(md) {
  .screenshot {
    height: 24rem;
  }
}

@include media-breakpoint-up(lg) {
  .screenshot {
    height: 26rem;
  }
}

@include media-breakpoint-up(xl) {
  .screenshot {
    height: 28rem;
  }
}

.todo {
  position: relative;
  left: 1rem;
  // background-color: $secondary;
}

@include media-breakpoint-up(md) {
  .todo {
    width: 90%;
    left: 10%;
  }
}

@include media-breakpoint-up(lg) {
  .todo {
    width: 85%;
    left: 15%;
  }
}

@include media-breakpoint-up(xl) {
  .todo {
    width: 80%;
    left: 20%;
  }
}

.realTree {
  color: $white !important;
  background: url(~media/real-tree-2.jpg) center center no-repeat scroll;
  background-size: cover;
  position: relative;
  margin-top: -2px;

  :global(.container) {
    position: relative;
  }
}

.realTreeCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    181deg,
    rgba(52, 37, 25, 0.8) 0%,
    rgba(112, 79, 53, 0.8) 5%
  );
  // opacity: 0.75;
}

.ratingSection {
  position: relative;
  text-align: center;
  background-color: $bg;
}

.ratingHeader {
  text-align: center;
  position: absolute;
  border-radius: 9999rem;
  background-color: $bg;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  padding-top: 3.75rem;
  top: -7rem;
  width: 17.5rem;
  height: 17.5rem;
}

@include media-breakpoint-up(md) {
  .ratingHeader {
    padding-top: 4rem;
    top: -8rem;
    width: 20rem;
    height: 20rem;
  }
}

@include media-breakpoint-up(lg) {
  .ratingHeader {
    padding-top: 4rem;
    top: -9rem;
    width: 22rem;
    height: 22rem;
  }
}

@include media-breakpoint-up(xl) {
  .ratingHeader {
    padding-top: 5rem;
    top: -10rem;
    width: 25rem;
    height: 25rem;
  }
}

.carouselItem {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 54rem;
}

.reviewItem {
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: left;
  background-color: $white;
}

.reviewCountry {
  width: 2rem;
  height: 2rem;
}

.cta {
  position: relative;
  background: url(~media/cta.svg) bottom right no-repeat scroll;
  background-color: #ccffe6;
  background-size: cover;
}

@include media-breakpoint-up(md) {
  .cta {
    background-position-x: center;
  }
}

.ctaCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    160deg,
    #ccff66 0%,
    #66ff99 15%,
    #00ffff 85%
  );
  // background-color: #006699;
  opacity: 0.8;
}


.footer {
  color: $white-70;
  background-color: $dark;

  :global(a) {
    color: $white;
  }
}

.legal a {
  color: $white-70;
}
